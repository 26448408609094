import React from 'react';
import { useNavigate } from 'react-router-dom';

const Credits = () => {
  const nav = useNavigate();

  return (
    <div className="credits">
      <p className="credits__logo">
        <img src="/media/badge.svg" alt="Badge" />
      </p>

      <div className="credits__content">
        <h1>Credits</h1>

        <h2>Images</h2>

        <p>
          Space Icons made by{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/authors/nikita-golubev"
          >
            Nikita Golubev
          </a>{' '}
          from{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/"
          >
            flaticon
          </a>
          .
        </p>

        <p>
          Badge icon made by{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/authors/pixel-perfect"
          >
            Pixel perfect
          </a>{' '}
          from{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/"
          >
            flaticon
          </a>
          .
        </p>

        <p>
          Japanese Character icons made by{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.freepik.com"
          >
            Freepik
          </a>{' '}
          from{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.flaticon.com/"
          >
            flaticon
          </a>
          .
        </p>

        <h2>Team</h2>

        <p>Experience Designer - Florencia Mostaccio</p>

        <p>Interaction Designer - Katrina Abbott</p>

        <p>Creative Manager - Olga Lavecchia</p>

        <p>Tech Lead - Davide Gaido</p>

        <p>Senior Developer - Mahsa Mohajer</p>

        <p>Animation - Nicolas Lee</p>

        <p>Senior Publishing Editor, Languages - Catriona McKenzie</p>

        <p>Associate Publishing Editor - Siobhan Moran</p>

        <p>Senior Production Controller - Karen Young</p>

        <p>Project Manager - Danielle Hindi</p>
      </div>

      <p className="credits__back">
        <button className="aHref" onClick={() => nav(-1)}>
          {' '}
          &lt; Back to results
        </button>
      </p>
    </div>
  );
};

export default Credits;
