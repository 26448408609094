import React from 'react';
import { connect } from 'react-redux';

import Error from '../components/error';
import Header from '../components/header';
import Landing from '../components/landing';
import Loading from '../components/loading';
import Results from '../components/results';

import QuizLinear from './quizzes/linear';
import QuizAudio from './quizzes/audio';
import QuizVideo from './quizzes/video';

import Api from '../modules/api';
import urlHasPreviewQueryString from '../modules/strings';
import ResultsMindtap from './ResultsMindtap';
import PreviewBanner from '../components/PreviewBanner';
import withLocation from '../modules/hocs';

const api = new Api();

class Quiz extends React.Component {
  constructor(props) {
    super(props);

    this.quiz = null;

    // Parse URL
    const uri = props.location.pathname.replace(/^\/([^/]+)\//, '');

    let matches;

    matches = uri.match(/^(.+)\/quiz\/([^/]+)\/in\/([^/]+)/);

    this.productSlug = matches ? matches[1] : null;
    this.quizSlug = matches ? matches[2] : null;
    this.chapterSlug = matches ? matches[3] : null;

    matches = uri.match(/\/question\/([^/]+)/);

    this.questionHashId = matches ? matches[1] : null;

    matches = uri.match(/\/result\/([^/]+)/);

    this.resultHashId = matches ? matches[1] : null;

    this.state = {
      error: null,
      started: !!this.questionHashId,
      ended: false,
      loading: !this.resultHashId,
      submitting: !!this.resultHashId,
      results: null,
      is_preview: urlHasPreviewQueryString(window.location.href),
    };
  }

  componentDidMount() {
    const quizUri = this.resultHashId
      ? `quiz/result/${this.resultHashId}/read/inline`
      : `p/${this.productSlug}/quiz/${this.quizSlug}/in/${this.chapterSlug}`;

    // Fetch quiz
    api
      .get(quizUri, this.state.is_preview)
      .then((response) => {
        if (this.resultHashId) {
          this.quiz = response.quiz;

          this.setState({
            submitting: false,
            ended: true,
            results: response,
          });
        } else {
          this.quiz = response;

          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        let { message } = error;

        if (error.statusCode === 404) {
          message = 'Page not found error 404';
        }

        this.setState({ error: message });
      });
  }

  start() {
    this.setState({ started: true });
  }

  submit() {
    this.setState({ submitting: true });

    api
      .post(
        'quiz/result/create/inline',
        {
          quiz_slug: this.quiz.quiz_slug,
          results: this.props.answers.answers,
        },
        this.state.is_preview
      )
      .then(
        (response) =>
          (window.location = `/p/${this.quiz.product_slug}/quiz/${this.quiz.quiz_slug}/in/${this.quiz.chapter_slug}/result/${response.hashid}${this.addPreviewString()}`)
      )
      .catch((error) => this.setState({ error: error.message.message }));
  }

  addPreviewString() {
    const { is_preview: isPreview } = this.state;
    return `${isPreview ? '?preview=true' : ''}`;
  }

  render() {
    const { results, is_preview: isPreview } = this.state;
    if (this.state.error) {
      return <Error message={this.state.error} />;
    }

    if (this.state.loading) {
      return <Loading />;
    }

    if (this.state.submitting) {
      return <Loading text="Calculating..." />;
    }

    if (this.state.ended) {
      return (
        <React.Fragment>
          {results.is_mindtap === true ? (
            <ResultsMindtap
              title={this.quiz.title}
              unit={this.quiz.chapter_name}
            />
          ) : (
            <React.Fragment>
              <Header title={this.quiz.title} unit={this.quiz.chapter_name} />
              <Results
                {...this.state.results}
                quiz={this.quiz}
                productSlug={this.productSlug}
                startAgainURL={`/p/${this.quiz.product_slug}/quiz/${this.quiz.quiz_slug}/in/${this.quiz.chapter_slug}${this.addPreviewString()}`}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      );
    }

    if (!this.state.started) {
      return (
        <Landing
          title={this.quiz.title}
          unit={this.quiz.chapter_name}
          clickHandler={() => this.start()}
        />
      );
    }

    const props = {
      submitClickHandler: this.submit.bind(this),
      questionHashId: this.questionHashId,
      ...this.quiz,
    };

    let quiz;

    switch (this.quiz.type) {
      case 'AUDIO':
        quiz = <QuizAudio {...props} isPreview={this.state.is_preview} />;

        break;
      case 'VIDEO':
        quiz = <QuizVideo {...props} isPreview={this.state.is_preview} />;

        break;
      case 'LINEAR':
      case 'LINEAR_MULTI_TRACK':
        quiz = (
          <QuizLinear
            submitClickHandler={() => this.submit()}
            questionHashId={this.questionHashId}
            quiz={this.quiz}
            isPreview={this.state.is_preview}
          />
        );

        break;
      default:
    }

    return (
      <React.Fragment>
        {isPreview && <PreviewBanner />}
        <Header title={this.quiz.title} unit={this.quiz.chapter_name} />
        {quiz}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

export default withLocation(connect(mapStateToProps)(Quiz));
