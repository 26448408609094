import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveAnswer } from '../../../actions';

import LinearQuestion from './linear-question';

import Button from '../../../components/button';
import Question from '../../../components/question';
import Prompt from '../../../components/Prompt';
import Answer from '../../../components/answer';
import Feedback from '../../../components/feedback';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import Options from '../../../components/options';
import Option from '../../../components/option';
import MultipleSelect from '../../../components/MultipleSelect';

const deepCopy = (object) => JSON.parse(JSON.stringify(object));

class LinearQuestionMultiSelect extends LinearQuestion {
  constructor(props, context) {
    super(props, context);

    // Null values are used to indicate blank spaces in selection
    const selected = new Array(this.props.correct_answer.length).fill(null);

    this.state = {
      ...this.state,
      selected,
    };

    this.submit = this.submit.bind(this);
    this.reset = this.reset.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(i, select) {
    const { options, selected, answer } = this.state;

    const newOptions = deepCopy(options);
    const newSelected = deepCopy(selected);

    // Disable toggle function if already submitted
    if (answer) {
      return;
    }

    if (select) {
      // Add to answer to selection if space available
      const nextAvailableIndex = newSelected.findIndex(
        (option) => option === null
      );
      if (nextAvailableIndex !== -1) {
        newSelected[nextAvailableIndex] = { ...newOptions[i] };
        newOptions[i].selected = select;
        newOptions[i].state = 'disabled';
      }
    } else {
      // Remove answer from selection
      const index = newSelected.findIndex(
        (option) => option !== null && option.text === newOptions[i].text
      );
      newSelected[index] = null;
      newOptions[i].selected = select;
      newOptions[i].state = 'neutral';
    }

    this.setState({
      ready: Boolean(newSelected.findIndex((option) => option === null) === -1),
      options: newOptions,
      selected: newSelected,
    });
  }

  submit() {
    const { selected, options } = this.state;
    const { correct_answer } = this.props;

    // Check whether answer is correct
    const answer =
      selected.length === correct_answer.length &&
      selected.every((option) => correct_answer.indexOf(option.text) > -1)
        ? 'correct'
        : 'incorrect';

    // Indicate which selected options were correct or incorrect
    selected.forEach((option) => {
      option.state =
        correct_answer.indexOf(option.text) > -1 ? 'correct' : 'incorrect';
    });

    // Highlight any correct options that were not selected
    options.forEach((option) => {
      if (correct_answer.indexOf(option.text) > -1 && !option.selected) {
        option.state = 'correct';
      }
    });

    this.saveAnswer({
      answer: selected.map((option) => option.text),
      isCorrect: answer === 'correct',
    });

    this.setState({
      answer,
      options,
    });
  }

  reset() {
    const { options } = this.state;

    if (this.state.answer) {
      return;
    }

    options.forEach((option) => {
      option.selected = false;
      option.state = 'neutral';
    });

    this.setState({
      ready: false,
      options,
      selected: new Array(this.props.correct_answer.length).fill(null),
    });
  }

  render() {
    // Populate selection components
    const selected = [];
    this.state.selected.forEach((option, i) => {
      if (option === null) {
        selected.push(null);
      } else {
        const index = this.state.options.findIndex(
          (o) => o.text === option.text
        );
        selected.push(
          <Option
            key={i}
            text={option.text}
            state={option.state}
            compact
            disabled={this.state.answer !== false}
            clickHandler={() => this.toggle(index, false)}
          />
        );
      }
    });

    // Populate option components
    const options = this.state.options.map((option, i) => (
      <Option
        key={option.id}
        text={option.text}
        state={option.state}
        disabled={this.state.answer !== false}
        clickHandler={option.selected ? () => {} : () => this.toggle(i, true)}
      />
    ));

    return (
      <div>
        <Question>
          <Progress />

          <p className="question__text">{this.props.instructions}</p>

          <Prompt type="primary">{this.props.prompt}</Prompt>

          <MultipleSelect
            correct={this.state.answer === 'correct'}
            incorrect={this.state.answer === 'incorrect'}
            resetClickHandler={this.reset}
          >
            {selected}
          </MultipleSelect>
        </Question>

        <Answer>
          <Options>{options}</Options>
          <Feedback
            display={this.state.answer === 'incorrect'}
            message={this.props.feedback}
          />
        </Answer>

        <Footer
          correct={this.state.answer === 'correct'}
          incorrect={this.state.answer === 'incorrect'}
          sticky
        >
          <Button
            text={this.state.answer ? this.props.submitText : 'Submit'}
            primary
            disabled={!this.state.ready}
            clickHandler={
              this.state.answer ? this.props.submitClickHandler : this.submit
            }
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});

LinearQuestionMultiSelect.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearQuestionMultiSelect);
