import React from 'react'; // eslint-disable-line no-unused-vars
import { connect } from 'react-redux'; // eslint-disable-line no-unused-vars

import Question from '../question';

class AudioVideoQuestion extends Question {
  constructor(props, context) {
    super(props, context);

    if (this.props.answer) {
      this.state.options = this.state.options.map((option) => {
        if (
          option.question_hashid === this.props.answer.question_hashid &&
          option.text === this.props.answer.answer
        ) {
          option.state = 'selected';

          this.state.ready = true;
        }

        return option;
      });
    }
  }

  submit() {
    const { options } = this.state;
    const i = options.findIndex((option) => option.state === 'selected');
    const answerText = options[i].text;
    const answer =
      answerText === this.props.correct_answer[0] ? 'correct' : 'incorrect';

    this.saveAnswer({
      answer: answerText,
      isCorrect: answer === 'correct',
    });

    this.setState({ answer });

    this.props.submitClickHandler();
  }

  saveAnswer(answer) {
    this.props.saveAnswer({
      question_hashid: this.props.hashid,
      answer: answer.answer,
      is_correct: answer.isCorrect,
    });
  }
}

export default AudioVideoQuestion;
