import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { saveAnswer } from '../../../actions';

import LinearQuestion from './linear-question';

import Button from '../../../components/button';
import Question from '../../../components/question';
import Answer from '../../../components/answer';
import Feedback from '../../../components/feedback';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import Options from '../../../components/options';
import Option from '../../../components/option';
import Match from '../../../components/match';

class LinearQuestionMatching extends LinearQuestion {
  constructor(props) {
    super(props);

    const subjects = [];
    const options = [];
    let index = 1;

    props.options[0].forEach((subject) => {
      subjects.push({
        option: null,
        state: 'neutral',
        text: subject,
        id: (index += 1),
      });
    });

    props.options[1].forEach((option) => {
      options.push({
        text: option,
        state: 'neutral',
        id: (index += 1),
      });
    });

    this.state = {
      ...this.state,
      activeSubject: 0,
      subjects,
      options,
    };

    this.select = this.select.bind(this);
    this.submit = this.submit.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(i, select) {
    if (this.state.answer) {
      return;
    }

    const { options, subjects } = this.state;
    let { activeSubject } = this.state;

    if (select) {
      if (subjects[activeSubject].option) {
        subjects[activeSubject].option.selected = false;
      }

      options[i].selected = true;

      subjects[activeSubject].option = options[i];

      // Highlight the next subject without an option
      activeSubject = this.state.subjects.findIndex(
        (subject) => !subject.option
      );
    } else {
      options.find(
        (option) => option.text === subjects[i].option.text
      ).selected = false;

      subjects[i].option = false;

      activeSubject = i;
    }

    this.setState({
      options,
      subjects,
      activeSubject,
    });

    this.setState({ ready: activeSubject === -1 });
  }

  select(i) {
    if (this.state.answer === false) {
      this.setState({ activeSubject: i });
    }
  }

  submit() {
    const { subjects } = this.state;

    const answerTexts = [];

    let answer = 'correct';

    this.props.correct_answer.forEach((option) => {
      const [subjectText, answerText] = option;

      const i = subjects.findIndex((subject) => subject.text === subjectText);

      subjects[i].correct_answer = answerText;
      subjects[i].state =
        subjects[i].option.text === answerText ? 'correct' : 'incorrect';

      answerTexts.push([subjectText, subjects[i].option.text]);

      if (subjects[i].state === 'incorrect') {
        answer = 'incorrect';
      }
    });

    this.saveAnswer({
      answer: answerTexts,
      isCorrect: answer === 'correct',
    });

    this.setState({
      answer,
      subjects,
    });
  }

  render() {
    const options = [];

    this.state.options.forEach((option, i) => {
      options.push(
        <Option
          key={option.id}
          text={option.text}
          state={option.selected ? 'disabled' : 'neutral'}
          clickHandler={option.selected ? () => {} : () => this.toggle(i, true)}
        />
      );
    });

    return (
      <div>
        <Question>
          <Progress />

          <p className="question__text">{this.props.instructions}</p>
          <Match
            activeSubject={this.state.activeSubject}
            subjects={this.state.subjects}
            disabled={this.state.answer !== false}
            optionClickHandler={this.toggle}
            subjectClickHandler={this.select}
          />
        </Question>

        <Answer>
          <Options>{options}</Options>
          <Feedback
            display={this.state.answer === 'incorrect'}
            message={this.props.feedback}
          />
        </Answer>

        <Footer
          correct={this.state.answer === 'correct'}
          incorrect={this.state.answer === 'incorrect'}
          sticky
        >
          <Button
            text={this.state.answer ? this.props.submitText : 'Submit'}
            primary
            disabled={!this.state.ready}
            clickHandler={
              this.state.answer ? this.props.submitClickHandler : this.submit
            }
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});

LinearQuestionMatching.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired,
  subjects: PropTypes.instanceOf(Array),
  correct_answer: PropTypes.instanceOf(Array).isRequired,
  submitClickHandler: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearQuestionMatching);
