import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ uri, alt }) => (
  <div>
    <img className="image" src={uri} alt={alt} />
  </div>
);

Image.propTypes = {
  uri: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
