import React from 'react';
import PropTypes from 'prop-types';

const Buttons = ({ justify, center, children }) => (
  <div
    className={`
        buttons
        ${justify ? ' buttons--justify' : ''}
        ${center ? ' buttons--center' : ''}
        `}
  >
    {children}
  </div>
);

Buttons.propTypes = {
  justify: PropTypes.bool,
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Buttons.defaultProps = {
  justify: false,
  center: false,
};

export default Buttons;
