import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

import MathJax from '../MathJax';

class Equation extends Component {
  state = {
    mathJaxRendered: false,
  };

  checkMathJaxReady = () => {
    const selectors = document.querySelectorAll('input.MathJax_Input');
    const inputsDictionary = {};

    const mathJaxNodes = document.querySelectorAll(
      '.question span.MathJax-Node'
    );

    // If MathJax has rendered inputs and MathJax nodes exist on the page
    if (selectors.length && mathJaxNodes.length) {
      // Event listeners for the inputs can be setup and added to the inputsDictionary
      selectors.forEach((el) => {
        const reassignableEl = el;
        const variableName = el.name;
        reassignableEl.readOnly = true;
        reassignableEl.value = variableName;
        if (inputsDictionary[variableName] === undefined) {
          inputsDictionary[variableName] = [];
        }
        el.addEventListener(
          'click',
          () => this.handleInputClick(variableName),
          false
        );
        inputsDictionary[variableName].push(el);
      });
      this.inputsDictionary = inputsDictionary;

      // All MathJax Nodes are ready to be displayed
      mathJaxNodes.forEach((el) => {
        el.style.visibility = 'visible';
      });

      // Spinner can be removed
      this.setState({ mathJaxRendered: true });
    }
  };

  // This runs last on the first equation question in the quiz
  contextDidFinishTypesetHandler = () => {
    this.checkMathJaxReady();
  };

  // This runs last on subsequent equation questions in the quiz
  nodeOnRenderHandler = () => {
    this.checkMathJaxReady();
  };

  CURRENT_CLASS = 'current';

  INCORRECT_CLASS = 'wrong';

  inputsDictionary = [];

  handleInputClick = (variableName) => {
    const { changeOptionsSetHandler } = this.props;
    changeOptionsSetHandler(variableName);
  };

  markWrongAnswers(wrongAnswers) {
    if (wrongAnswers.length > 0) {
      Object.values(this.inputsDictionary).forEach((arr) => {
        if (arr) {
          arr.forEach((el) => {
            if (wrongAnswers.includes(el.name)) {
              el.classList.add(this.INCORRECT_CLASS);
            }
          });
        }
      });
    }
  }

  fillInputElementWithSelectedOption(text) {
    const { currentVariable } = this.props;
    // Given we need to await the rendering of mathJax, and this function is called on render
    // We check if we do have an array of inputs
    if (this.inputsDictionary[currentVariable] !== undefined) {
      const inputsForCurrentVariable = this.inputsDictionary[currentVariable];
      // What a masterpiece
      Object.values(this.inputsDictionary).forEach((arr) =>
        arr.forEach((el) => {
          el.classList.remove(this.CURRENT_CLASS);
        })
      );
      inputsForCurrentVariable.forEach((el) => {
        const reassignableEl = el;
        if (text !== '') {
          reassignableEl.value = text;
        }
        reassignableEl.classList.add(this.CURRENT_CLASS);
      });
    }
  }

  /**
   * Called as refs by parent
   */
  resetInputs(type) {
    if (this.inputsDictionary !== undefined) {
      Object.keys(this.inputsDictionary).forEach((variableName) => {
        this.inputsDictionary[variableName].forEach((el) => {
          if (type === 'hard') {
            const reassignableEl = el;
            reassignableEl.value = variableName;
            el.classList.remove(this.CURRENT_CLASS);
          }
          el.classList.remove(this.INCORRECT_CLASS);
        });
      });
    }
  }

  render() {
    const { mathJaxRendered } = this.state;
    const { children, selectedOptionText } = this.props;
    // We now want to replace the instance of !x! with an FormInput
    // We match with regex and substitute while keeping the variable
    const regex = /!(.*?)!/g;
    const equationLatex = children.replace(regex, '{\\FormInput[6][]{$1}}');
    const gatheredEquation = `\\begin{gathered} ${equationLatex} \\end{gathered}`;
    this.fillInputElementWithSelectedOption(selectedOptionText);

    // There is a reason for having this here
    // MathJax needs to be on the DOM to be marked as rendered
    // We cannot do a simple if !rendered return <Loading>, we need to hide it programmatically
    return (
      <React.Fragment>
        {!mathJaxRendered && (
          <ReactLoading type="spin" className="MathJax_loader" />
        )}
        <MathJax.Context
          input="tex"
          didFinishTypeset={this.contextDidFinishTypesetHandler}
        >
          <MathJax.Node inline onRender={this.nodeOnRenderHandler}>
            {gatheredEquation}
          </MathJax.Node>
        </MathJax.Context>
      </React.Fragment>
    );
  }
}

Equation.propTypes = {
  children: PropTypes.node.isRequired,
  selectedOptionText: PropTypes.string.isRequired,
  currentVariable: PropTypes.string.isRequired,
  changeOptionsSetHandler: PropTypes.func.isRequired,
};

export default Equation;
