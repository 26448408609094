import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

class Video extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    if (!document.getElementById('wistia_external')) {
      const wistiaExternal = document.createElement('script');
      wistiaExternal.id = 'wistia_external';
      wistiaExternal.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaExternal.type = 'text/javascript';
      wistiaExternal.async = true;
      document.body.appendChild(wistiaExternal);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: this.props.hashedId,
      onReady: (video) => {
        this.handle = video;
        this.setState({ loading: false });
      },
    });
  }

  componentWillUnmount() {
    if (this.handle) {
      this.handle.remove();
    }
  }

  render() {
    return (
      <div className="video__container">
        {this.state.loading && (
          <ReactLoading
            type="spin"
            color={this.props.loadingColor || 'white'}
            className="video__loading"
            width={100}
          />
        )}
        <div
          className={`wistia_embed wistia_async_${this.props.hashedId} playerColor=424a59`}
        />
      </div>
    );
  }
}

Video.propTypes = {
  hashedId: PropTypes.string.isRequired,
};

export default Video;
