import React from 'react';
import PropTypes from 'prop-types';

const Option = ({
  wide,
  center,
  compact,
  next,
  state,
  clickHandler,
  text,
  disabled,
}) => (
  <div
    className={`
    option
    ${wide ? 'option--wide' : ''}
    ${center ? 'option--center' : ''}
    ${compact ? 'option--compact' : ''}
    `}
  >
    <button
      className={`
      option__inner
      ${wide ? 'option__inner--wide' : ''}
      ${center ? 'option__inner--center' : ''}
      ${next ? 'option__inner--next' : ''}
      ${state === 'disabled' ? 'option__inner--disabled' : ''}
      ${state === 'correct' ? 'option__inner--correct' : ''}
      ${state === 'incorrect' ? 'option__inner--incorrect' : ''}
      ${state === 'selected' ? 'option__inner--selected' : ''}
      ${state === 'completed' ? 'option__inner--completed' : ''}
      `}
      disabled={disabled}
      onClick={clickHandler}
    >
      {text}
    </button>
  </div>
);

Option.propTypes = {
  wide: PropTypes.bool,
  center: PropTypes.bool,
  compact: PropTypes.bool,
  next: PropTypes.bool,
  state: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Option.defaultProps = {
  wide: false,
  center: false,
  compact: false,
  next: false,
  disabled: false,
};

export default Option;
