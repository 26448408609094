import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ primary, prev, next, disabled, clickHandler, text }) => (
  <button
    className={`
    button
    ${primary ? ' button--primary' : ''}
    ${prev ? ' button--prev' : ''}
    ${next ? ' button--next' : ''}
    `}
    disabled={disabled}
    onClick={clickHandler}
  >
    {text}
  </button>
);

Button.propTypes = {
  primary: PropTypes.bool,
  prev: PropTypes.bool,
  next: PropTypes.bool,
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

Button.defaultProps = {
  primary: true,
  prev: false,
  next: false,
  disabled: false,
};

export default Button;
