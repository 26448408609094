import React from 'react';
import PropTypes from 'prop-types';

const Options = ({ wide, center, padded, children }) => (
  <div
    className={`
        options
        ${wide ? ' options--wide' : ''}
        ${center ? ' options--center' : ''}
        ${padded ? ' options--padded' : ''}
        `}
  >
    {children}
  </div>
);

Options.propTypes = {
  wide: PropTypes.bool,
  center: PropTypes.bool,
  padded: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Options.defaultProps = {
  wide: false,
  center: false,
  padded: false,
};

export default Options;
