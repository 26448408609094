import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactGA from 'react-ga';

import Audio from './audio';
import Buttons from './buttons';
import Button from './button';
import Transcript from './transcript';

class Results extends Component {
  constructor(props) {
    super(props);
    this.variation = Math.random() >= 0.5 ? 'male' : 'female';
    this.state = {
      transcriptVisible: false,
      displayDetailedResults: false,
    };
  }

  formatPrompt(item) {
    switch (item.question.type) {
      case 'MULTIPLE_WITH_IMAGE':
        return `Image: ${item.question.alt_tag}`;
      case 'MULTIPLE_WITH_AUDIO':
      case 'TRUEFALSE_WITH_AUDIO':
        return `Audio: ${item.question.audio_transcript}`;
      case 'MULTIPLE_WITH_VIDEO':
      case 'TRUEFALSE_WITH_VIDEO':
        return 'Video';
      case 'CLOZE':
        return item.question.options
          .map((option) =>
            option.order === -1 ? '' : option.locked ? option.option : '___'
          )
          .join(' ');
      default:
        return item.question.prompt;
    }
  }

  formatAnswer(item) {
    return item.human_readable_answer;
  }

  formatCorrectAnswer(item) {
    return item.question.human_readable_correct_answer;
  }

  render() {
    const { quiz, startAgainURL } = this.props;
    const { audio_transcript: audioTranscript } = quiz;
    const { displayDetailedResults, transcriptVisible } = this.state;

    // Remove bottom padding on body
    document.body.className = 'dark_blue_bg';

    let score = '';
    if (this.props.correct_percentage >= 70) {
      score = 'high';
    } else if (this.props.correct_percentage >= 35) {
      score = 'medium';
    } else if (this.props.correct_percentage < 35) {
      score = 'low';
    }

    const items = this.props.quiz_result_data.map((item) => (
      <div
        key={item.question_hashid}
        className={`results__item results__item--${item.is_correct ? 'correct' : 'incorrect'}`}
      >
        <div className="results__instruction">
          Instruction ({item.question.instructions})
        </div>
        <div className="results__prompt">{this.formatPrompt(item)}</div>
        <div className="results__prompt-secondary">
          {item.question.prompt_secondary}
        </div>
        <div className="results__hint">
          {item.question.hint ? `Hint: ${item.question.hint}` : ''}
        </div>
        <div className="results__answer">
          <strong>Your answer:</strong> {this.formatAnswer(item)}
        </div>
        {item.is_correct ? (
          ''
        ) : (
          <div className="results__correct-answer">
            <strong>Correct answer:</strong> {this.formatCorrectAnswer(item)}
          </div>
        )}
        {!item.is_correct && item.question.feedback ? (
          <div className="results__feedback">{item.question.feedback}</div>
        ) : (
          ''
        )}
      </div>
    ));

    return (
      <div className="results">
        <div className="results__top">
          <div
            className={`
        results__percentage
        results__percentage--${score}
        `}
          >
            {this.props.correct_percentage}%
          </div>
          <div
            className={`
        results__image
        results__image--${this.variation}
        results__image--${score}
        `}
          />
          {this.props.quiz.type === 'AUDIO' ? (
            <div>
              <Audio uri={this.props.quiz.audio_track} />
              {audioTranscript && (
                <Buttons justify>
                  <div />
                  <Button
                    text={`${this.state.transcriptVisible ? 'Hide' : 'Show'} transcript`}
                    next
                    clickHandler={() =>
                      this.setState({ transcriptVisible: !transcriptVisible })
                    }
                  />
                </Buttons>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="results__bottom">
          {this.state.transcriptVisible ? (
            <Transcript text={audioTranscript} />
          ) : (
            <div>
              <ReactGA.OutboundLink
                eventLabel="savePDFResults"
                to={this.props.pdf_url}
                className="results__button"
              >
                Save PDF Results
              </ReactGA.OutboundLink>
              <ReactGA.OutboundLink
                eventLabel="startQuizAgain"
                to={startAgainURL}
                className="results__button"
              >
                Start Quiz Again
              </ReactGA.OutboundLink>
              <ReactGA.OutboundLink
                eventLabel="startQuizAgain"
                to="#results"
                className="results__button"
                onClick={() =>
                  this.setState({
                    displayDetailedResults: !displayDetailedResults,
                  })
                }
              >
                {displayDetailedResults ? 'Hide Results' : 'Show Results'}
              </ReactGA.OutboundLink>
              <div id="results" className="results__list">
                {displayDetailedResults && items}
              </div>
            </div>
          )}
        </div>
        <div className="results__footer"> </div>
      </div>
    );
  }
}

Results.propTypes = {
  correct_percentage: PropTypes.number.isRequired,
  quiz: PropTypes.instanceOf(Object).isRequired,
  quiz_result_data: PropTypes.instanceOf(Array).isRequired,
  pdf_url: PropTypes.string.isRequired,
  startAgainURL: PropTypes.string.isRequired,
};

export default Results;
