import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AudioPlayerBar extends Component {
  constructor(props, context) {
    super(props, context);
    this.audioPlayerControls = React.createRef();
  }

  bubbleClickToHandler(event) {
    const { onClickHandler } = this.props;
    const audioPlayerControlsWidth =
      this.audioPlayerControls.current.offsetWidth;
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; // x position within the element.
    const percentage = Math.round((x / audioPlayerControlsWidth) * 100, 2);
    onClickHandler(percentage);
  }

  render() {
    const { children } = this.props;
    return (
      <div
        className="audio__bar"
        onMouseUp={(event) => this.bubbleClickToHandler(event)}
        role="presentation"
        ref={this.audioPlayerControls}
      >
        {children}
      </div>
    );
  }
}

AudioPlayerBar.propTypes = {
  onClickHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default AudioPlayerBar;
