import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createBrowserHistory } from 'history';

import { quizShape } from '../../shapes';
import { setProgress } from '../../actions';

import LinearQuestionMultiple from './linear/multiple';
import LinearQuestionMultipleWithAudio from './linear/multiple-with-audio';
import LinearQuestionMultipleWithImage from './linear/MultipleWithImage';
import LinearQuestionMultipleWithVideo from './linear/MultipleWithVideo';
import LinearQuestionShortInput from './linear/LinearQuestionShortInput';

import LinearQuestionMatching from './linear/matching';
import LinearQuestionTapAdd from './linear/tap-add';
import LinearQuestionCloze from './linear/LinearQuestionCloze';
import LinearQuestionEquation from './linear/LinearQuestionEquation';
import LinearQuestionMultiSelect from './linear/LinearQuestionMultiSelect';

const history = createBrowserHistory();

class QuizLinear extends React.Component {
  constructor(props, context) {
    super(props, context);

    const { quiz, questionHashId } = props;
    const question = Math.max(
      0,
      quiz.questions.findIndex(
        (_question) => _question.hashid === questionHashId
      )
    );

    this.state = {
      question,
    };

    this.setProgress(question);
  }

  setProgress(question) {
    const { quiz } = this.props;
    this.props.setProgress(
      Math.round((100 / quiz.questions.length) * question)
    );
  }

  submit() {
    const { quiz } = this.props;
    if (this.state.question === quiz.questions.length - 1) {
      this.props.submitClickHandler();
    } else {
      const question = this.state.question + 1;

      this.setState({ question });

      this.setProgress(question);
    }
  }

  render() {
    const { quiz, isPreview } = this.props;
    const question = quiz.questions[this.state.question];

    history.push(
      `/p/${quiz.product_slug}/quiz/${quiz.quiz_slug}/in/${quiz.chapter_slug}/question/${question.hashid}${isPreview ? '/?preview=true' : ''}`
    );

    const props = {
      submitText:
        this.state.question === quiz.questions.length - 1
          ? 'Finish Quiz'
          : 'Next',
      submitClickHandler: this.submit.bind(this),
      ...question,
    };

    switch (question.type) {
      case 'MULTIPLE':
      case 'TRUEFALSE':
        return <LinearQuestionMultiple key={question.hashid} {...props} />;
      case 'MULTI_SELECT':
        return <LinearQuestionMultiSelect key={question.hashid} {...props} />;
      case 'TRUEFALSE_WITH_AUDIO':
      case 'MULTIPLE_WITH_AUDIO':
        return (
          <LinearQuestionMultipleWithAudio key={question.hashid} {...props} />
        );
      case 'TRUEFALSE_WITH_VIDEO':
      case 'MULTIPLE_WITH_VIDEO':
        return (
          <LinearQuestionMultipleWithVideo key={question.hashid} {...props} />
        );
      case 'MULTIPLE_WITH_IMAGE':
        return (
          <LinearQuestionMultipleWithImage key={question.hashid} {...props} />
        );
      case 'MATCHING':
        return <LinearQuestionMatching key={question.hashid} {...props} />;
      case 'TAPADD':
        return <LinearQuestionTapAdd key={question.hashid} {...props} />;
      case 'CLOZE':
        return <LinearQuestionCloze key={question.hashid} {...props} />;
      case 'SHORTINPUT':
        return <LinearQuestionShortInput key={question.hashid} {...props} />;
      case 'EQUATION':
        return (
          <LinearQuestionEquation
            key={question.hashid}
            submitText={
              this.state.question === quiz.questions.length - 1
                ? 'Finish Quiz'
                : 'Next'
            }
            submitClickHandler={() => this.submit()}
            question={question}
            options={question.options}
            hashid={question.hashid}
          />
        );
      default:
        return <p>Sorry, something went wrong.</p>;
    }
  }
}

QuizLinear.propTypes = {
  submitClickHandler: PropTypes.func.isRequired,
  questionHashId: PropTypes.string,
  quiz: quizShape.isRequired,
  isPreview: PropTypes.bool,
};

QuizLinear.defaultProps = {
  isPreview: false,
  questionHashId: null,
  forceRepeatWrongAnswer: false,
};

const mapDispatchToProps = (dispatch) => ({
  setProgress: (percentage) => dispatch(setProgress(percentage)),
});

export default connect(null, mapDispatchToProps)(QuizLinear);
