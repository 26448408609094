import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ message }) => (
  <div className="error">
    <div className="error__content">
      <p className="error__title">Looks like you are lost in space.</p>
      <p className="error__message">
        {message || 'An unexpected error occurred.'}
      </p>
      <a className="error__button" href="https://www.nelsonnet.com.au/">
        Take me to NelsonNet
      </a>
    </div>
  </div>
);

Error.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Error;
