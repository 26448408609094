import React from 'react';
import PropTypes from 'prop-types';

const Question = ({ alt, children }) => (
  <div className="container">
    <div
      className={`
          question
          ${alt ? ' question--alt' : ''}
          `}
    >
      {children}
    </div>
  </div>
);

Question.propTypes = {
  alt: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Question.defaultProps = {
  alt: false,
};

export default Question;
