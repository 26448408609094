import React from 'react';
import PropTypes from 'prop-types';

const MultipleSelect = ({
  correct,
  incorrect,
  children,
  resetClickHandler,
}) => {
  const disabled = Boolean(!children.length || correct || incorrect);

  const nextIndex = children.findIndex((option) => option === null);
  children.forEach((option, i) => {
    if (option === null) {
      children[i] = (
        <div
          key={i}
          className={` multipleselect__input 
        ${i === nextIndex ? ' multipleselect__input--selected' : ' multipleselect__input--disabled'}
        `}
          role="presentation"
        >
          &nbsp;
        </div>
      );
    }
  });
  return (
    <div className="multipleselect">
      {children}
      <div className="multipleselect__footer">
        <button
          type="button"
          className={`multipleselect__reset 
          ${disabled && ' multipleselect__reset--disabled'}`}
          onClick={disabled ? () => {} : resetClickHandler}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

MultipleSelect.propTypes = {
  correct: PropTypes.bool.isRequired,
  incorrect: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  resetClickHandler: PropTypes.func,
};

MultipleSelect.defaultProps = {
  resetClickHandler: '',
};

export default MultipleSelect;
