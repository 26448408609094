import React from 'react';
import PropTypes from 'prop-types';

const Answer = ({ active, children }) => (
  <div className="container">
    <div
      className={`
      answer
      ${active ? ' answer--active' : ''}
      `}
    >
      {children}
    </div>
  </div>
);

Answer.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Answer.defaultProps = {
  active: false,
};

export default Answer;
