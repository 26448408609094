import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '../../../components/button';
import Question from '../../../components/question';
import Answer from '../../../components/answer';
import Footer from '../../../components/footer';
import Progress from '../../../components/progress';
import Options from '../../../components/options';
import Option from '../../../components/option';
import Equation from '../../../components/Equation';
import LinearQuestion from './linear-question';

import { questionShape } from '../../../shapes';
import { saveAnswer } from '../../../actions';
import MathJax from '../../../MathJax';

const initialState = {
  ready: false,
  answer: false,
  selectedOptionsInSets: {},
  isWrong: false,
  currentVariable: null,
};

class LinearQuestionEquation extends LinearQuestion {
  constructor(props) {
    super(props);
    this.equationRef = React.createRef();
    // Deep copy initial state
    this.state = JSON.parse(JSON.stringify(initialState));

    // Set state and initialState with the first question variable from props
    const { question } = this.props;
    const currentSet = question.options[0];
    const currentVariable = Object.keys(currentSet)[0];
    this.state.currentVariable = currentVariable;
    initialState.currentVariable = currentVariable;
  }

  findSetByVariable(variableName) {
    const { question } = this.props;
    return question.options.find((set) => set[variableName] !== undefined);
  }

  selectOptionInCurrentSet(option) {
    const { currentVariable, answer } = this.state;
    const { question } = this.props;
    if (answer) {
      return;
    }

    const selectedOptionsInSets = {
      ...this.state.selectedOptionsInSets,
      [currentVariable]: option,
    };
    this.setState(() => ({ selectedOptionsInSets }));

    if (Object.keys(selectedOptionsInSets).length === question.options.length) {
      this.setState({ ready: true });
    }
  }

  changeDataSet(variableName) {
    this.setState({
      currentVariable: variableName,
    });
  }

  nodeOnRenderHandler = () => {
    const mathJaxNodes = document.querySelectorAll('.answer span.MathJax-Node');
    if (mathJaxNodes.length) {
      mathJaxNodes.forEach((el) => {
        el.style.visibility = 'visible';
      });
    }
  };

  submit() {
    // This is a bit hacky
    // If we have a list of wrong answers, this is a try again state
    // We reset and return
    // TODO refactor for more elegance
    if (this.state.answer) {
      if (this.state.isWrong) {
        this.reset('soft');
      } else {
        this.props.submitClickHandler();
      }
      return;
    }

    const { selectedOptionsInSets } = this.state;
    const { question } = this.props;

    const wrongAnswers = [];
    let correctAnswers = 0;
    question.correct_answer.forEach((arr) => {
      const variableName = arr[0];
      const correctAnswer = arr[1];

      if (selectedOptionsInSets[variableName] === correctAnswer) {
        correctAnswers += 1;
      } else {
        wrongAnswers.push(variableName);
      }
    });

    const answer =
      question.correct_answer.length === correctAnswers
        ? 'correct'
        : 'incorrect';
    const isWrong = answer === 'incorrect';
    if (isWrong) {
      this.equationRef.current.markWrongAnswers(wrongAnswers);
    }

    this.saveAnswer({
      answer: selectedOptionsInSets,
      isCorrect: !isWrong,
    });

    this.setState({ answer, isWrong });
  }

  reset(type) {
    // We reset on the ref first as we are removing all state
    // Thus it will need a retrigger to highlight current option
    this.equationRef.current.resetInputs(type);
    switch (type) {
      case 'hard':
        this.setState(JSON.parse(JSON.stringify(initialState)));
        break;
      default:
        this.setState({ answer: false, isWrong: false });
        break;
    }
  }

  render() {
    const { currentVariable, selectedOptionsInSets, isWrong, answer, ready } =
      this.state;
    const { question, submitText } = this.props;
    // This question type has x sets of options
    // First thing we do is select the current set (by an index in the state)
    // Sets are object of {variable: [opt1, opt2, opt3]}
    // We extract the variable name and the options array
    // TODO: sanity check to make sure it receives only one index from .keys .values
    const currentSet = this.findSetByVariable(currentVariable);
    const currentOptions = Object.values(currentSet)[0];
    // Extract required variables from metadata
    const { variables_latex: variablesLatex } = question.equation_metadata;

    let selectedItemInCurrentSet = selectedOptionsInSets[currentVariable];
    if (selectedItemInCurrentSet === undefined) {
      selectedItemInCurrentSet = '';
    }

    // Button text changes according to state
    const completedButtonText = isWrong === true ? 'Try Again' : submitText;
    const submitButtonText = answer ? completedButtonText : 'Submit';

    console.log(`LinearQuestionEquation ready: ${ready}`);

    return (
      <div>
        <Question>
          <Progress />

          <p>
            {question.prompt.split('\n').map((item, key) => (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={key}>
                {item}
                <br />
              </React.Fragment>
            ))}
          </p>

          <Equation
            selectedOptionText={selectedItemInCurrentSet}
            ref={this.equationRef}
            currentVariable={currentVariable}
            changeOptionsSetHandler={(variableName) =>
              this.changeDataSet(variableName)
            }
          >
            {question.equation}
          </Equation>

          <button
            className={`droptarget__reset ' + ${selectedItemInCurrentSet.length ? '' : 'droptarget__reset--disabled'}`}
            onClick={() => this.reset('hard')}
          >
            Reset
          </button>
        </Question>

        <Answer>
          <p>{question.instructions}</p>
          <Options>
            <strong>{currentVariable}</strong>
            {' ='}
            {variablesLatex[currentVariable] && (
              <React.Fragment>
                {' '}
                <MathJax.Context input="tex">
                  <MathJax.Node inline onRender={this.nodeOnRenderHandler}>
                    {variablesLatex[currentVariable]}
                  </MathJax.Node>
                </MathJax.Context>
                {' = '}
              </React.Fragment>
            )}
            {currentOptions.map((option, i) => (
              <Option
                // https://github.com/yannickcr/eslint-plugin-react/issues/1123
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                text={option}
                state={
                  selectedItemInCurrentSet === option ? 'selected' : 'neutral'
                }
                disabled={answer !== false}
                clickHandler={() => this.selectOptionInCurrentSet(option)}
              />
            ))}
          </Options>
        </Answer>

        <Footer
          correct={answer === 'correct'}
          incorrect={answer === 'incorrect'}
          sticky
        >
          <Button
            text={submitButtonText}
            primary
            disabled={!ready}
            clickHandler={() => this.submit()}
          />
        </Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  answers: state.answers,
});

const mapDispatchToProps = (dispatch) => ({
  saveAnswer: (answer) => dispatch(saveAnswer(answer)),
});

LinearQuestionEquation.propTypes = {
  question: questionShape.isRequired,
  submitText: PropTypes.string.isRequired,
  submitClickHandler: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinearQuestionEquation);
