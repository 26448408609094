import axios from 'axios';

class Api {
  constructor(config) {
    this.config = config;

    this.params = { token: 'missing-token' };
    this.headers = { Authorization: `Bearer ${this.params.token}` };
  }

  async get(uri, preview = false) {
    const url = `${process.env.REACT_APP_API_URL}${uri}${preview ? '?preview=true' : ''}`;
    const rejectUnauthorized = !(
      process.env.REACT_APP_DISABLE_SSL_CHECK === 'true'
    ); // Disable cert check on testing

    const response = await axios.get(url, {
      headers: this.headers,
      rejectUnauthorized,
    });
    return response.data;
  }

  async post(uri, body, preview = false) {
    const url = `${process.env.REACT_APP_API_URL}${uri}${preview ? '?preview=true' : ''}`;

    const response = await axios.post(url, body, { headers: this.headers });
    return response.data;
  }
}

export default Api;
