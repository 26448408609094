import React from 'react';
import PropTypes from 'prop-types';

const Landing = ({ title, unit, clickHandler }) => (
  <div className="landing">
    <div className="landing__logo" />

    <p className="landing__title">{title}</p>
    <p className="landing__unit__title">{unit}</p>

    <button className="landing__start" onClick={clickHandler}>
      Start quiz!
    </button>
  </div>
);

Landing.propTypes = {
  title: PropTypes.string.isRequired,
  clickHandler: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
};

export default Landing;
