import React from 'react';
import PropTypes from 'prop-types';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.toggle = this.toggle.bind(this);

    this.state = {
      visible: false,
    };
  }

  toggle(event) {
    event.currentTarget.blur();

    const visible = !this.state.visible;

    const container = event.target.parentNode.querySelector(
      '.tooltip__container'
    );

    if (container) {
      if (visible) {
        container.style.display = 'inline-block';

        this.timeout = setTimeout(() => this.setState({ visible }), 50);
      } else {
        this.setState({ visible });

        // Remove tooltip from DOM after CSS transition
        this.timeout = setTimeout(() => {
          container.style.display = 'none';
        }, 200);
      }
    }
  }

  render() {
    return (
      <div className="tooltip" role="presentation" onClick={this.toggle}>
        <button className="tooltip__label">
          {this.state.visible
            ? this.props.labelVisible
            : this.props.labelHidden}
        </button>

        <div
          className={`
          tooltip__container
          ${this.state.visible ? 'tooltip__container--visible' : ''}
          `}
        >
          <div className="tooltip__arrow" />

          <div className="tooltip__text">{this.props.text}</div>
        </div>
      </div>
    );
  }
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  labelVisible: PropTypes.string.isRequired,
  labelHidden: PropTypes.string.isRequired,
};

export default Tooltip;
