import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AudioPlayerScrubButton from './AudioPlayerScrubButton';
import AudioPlayerBar from './AudioPlayerBar';

class AudioPlayerControls extends Component {
  constructor(props, context) {
    super(props, context);
    this.audioPlayerBar = React.createRef();
    this.state = {
      dragging: false,
    };
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.checkDragging, false);
    window.addEventListener('touchmove', this.checkDragging, false);
    window.addEventListener('mouseup', this.stopDragging, false);
    window.addEventListener('touchend', this.stopDragging, false);
  }

  checkDragging = (event) => {
    const { dragging } = this.state;
    if (dragging) {
      this.bubbleDragToHandler(event);
    }
  };

  stopDragging = () => {
    const { dragging } = this.state;
    if (dragging) {
      this.setState({ dragging: false });
    }
  };

  bubbleDragToHandler(event) {
    const { scrubHandler } = this.props;
    const { current: currentAudioPlayerBar } = this.audioPlayerBar;
    const {
      offsetWidth: audioPlayerControlsWidth,
      offsetLeft: audioPlayerControlsLeft,
    } = currentAudioPlayerBar.audioPlayerControls.current;
    let x;
    // Get x position within the element
    if (event.type === 'touchmove') {
      // Use the first touch point even if the user provides multiple touch points
      x = event.changedTouches.item(0).clientX - audioPlayerControlsLeft;
    } else {
      x = event.clientX - audioPlayerControlsLeft;
    }
    let boundedX = x;
    if (x > audioPlayerControlsWidth) {
      boundedX = audioPlayerControlsWidth;
    }
    if (x < 0) {
      boundedX = 0;
    }
    const percentage = Math.round(
      (boundedX / audioPlayerControlsWidth) * 100,
      2
    );

    scrubHandler(percentage);
  }

  render() {
    const { scrubHandler, percentage } = this.props;
    const { dragging } = this.state;
    return (
      <AudioPlayerBar
        onClickHandler={(event) => {
          if (!dragging) {
            scrubHandler(event);
          }
        }}
        role="presentation"
        ref={this.audioPlayerBar}
      >
        <div className="audio__progress" style={{ width: `${percentage}%` }}>
          <AudioPlayerScrubButton
            onClickHandler={() => this.setState({ dragging: true })}
            onTouchMoveHandler={() => this.setState({ dragging: true })}
          />
        </div>
      </AudioPlayerBar>
    );
  }
}

AudioPlayerControls.propTypes = {
  scrubHandler: PropTypes.func.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default AudioPlayerControls;
