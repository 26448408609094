import React from 'react';
import { useSelector } from 'react-redux';

const Progress = () => {
  const progress = useSelector((state) => state.progress);

  return (
    <div className="progress">
      <div
        className="progress__bar"
        style={{ width: `${progress.percentage}%` }}
      />
    </div>
  );
};

export default Progress;
