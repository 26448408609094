import React from 'react';
import PropTypes from 'prop-types';

const Transcript = ({ text }) => <div className="transcript">{text}</div>;

Transcript.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Transcript;
